.App {
  font-family: sans-serif;
  text-align: center;
}

section {
  display: grid;
  place-items: center;
  height: 100vh;
  font-size: 32px;
  font-weight: bold;
}

#about {
  margin-top: -80px;
}

#service,
#contact {
  color: #3b3b3c;
  background: #eaf2fa;
}

.nav {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: -5px;
  z-index: 2;
  height: 70px;
  min-height: 70px;
  width: calc(100% - 1.5rem);
  background-color: #fff;
  padding: 0 1rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
}

.nav__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.nav__container__actions {
  display: inline-flex;
  flex-flow: row nowrap;
  padding-left: 50px;
  flex: 1;
}

.active {
  font-weight: bold;
}

ul {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  list-style: none;
  padding: 0;
}

li {
  cursor: pointer;
}

li:hover {
  text-decoration: underline;
}
